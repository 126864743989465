<template>
  <div class="treatment-plan-form container">
    <h1>New Calendar</h1>
    <label>Select a Plan</label>
    <Multiselect
      :options="treatments"
      v-model="model.treatment"
      class="multiselect"
      track-by="id"
      label="name"
    />
    <label>Select a Regimen</label>
    <Multiselect
      :options="regimenOptions"
      v-model="model.regimen"
      class="multiselect"
      :customLabel="makeCycleLabel"
    />
    <label>Add Blood Clot Prevention</label>
    <Multiselect
      :options="model.treatment ? model.treatment.bloodClotPrevention : []"
      v-model="model.clotPrevention"
      class="multiselect"
      :customLabel="makeOptionLabel"
    />
    <label>Add Antiviral Prophylaxis</label>
    <Multiselect
      :options="model.treatment ? model.treatment.antiviralTreatment : []"
      v-model="model.antiviralTreatment"
      class="multiselect"
      :customLabel="makeOptionLabel"
    />
    <label>Add Pre-Meds</label>
    <Multiselect
      :options="model.treatment ? model.treatment.preMedOptions : []"
      v-model="model.preMeds"
      class="multiselect"
      :customLabel="makeOptionLabel"
      multiple
    />
    <FormInput label="Patient ID" v-model="model.patientId"/>
    <label>Start Date</label>
    <flatPickr v-model="model.startDate" class="picker"/>
    <Button @click="generateCalendar">Build Calendar</Button>
  </div>
</template>
<script>
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useQuery } from '@urql/vue';
import Button from '@/components/form/Button.vue';
import FormInput from '@/components/form/FormInput.vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import rangeParser from 'parse-numeric-range';
import flatPickr from 'vue-flatpickr-component';
import CalendarGenerator from '@/utilities/CalendarGenerator';
import 'flatpickr/dist/flatpickr.css';
import CreateCalendarPDF from '@/utilities/CreateCalendarPDF';
import testData from '@/utilities/testCalendarData';
import GetTreatments from '@/queries/GetTreatments';

export default {
  components: {
    Button,
    FormInput,
    Multiselect,
    flatPickr,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const options = [
      'Dexamethasone-something-somehting',
      'Option 2',
    ];

    // treatments from urql
    const treatmentResult = useQuery({
      query: GetTreatments,
    });

    const treatments = computed(() => {
      if (treatmentResult.data.value && treatmentResult.data.value.treatments) {
        return treatmentResult.data.value.treatments;
      }
      return [];
    });

    const model = reactive({
      treatment: null,
      regimen: null,
      clotPrevention: null,
      antiviralTreatment: null,
      preMeds: [],
      startDate: '',
      patientId: '',
    });

    function makeCycleLabel(value) {
      return `Cycle ${value}`;
    }

    function makeOptionLabel(value) {
      return value.medication.name;
    }

    function generateCalendar() {
      const calOptions = {
        treatmentPlan: model.treatment,
        regimen: model.regimen,
        bloodClot: model.clotPrevention,
        antiViral: model.antiviralTreatment,
        preMeds: model.preMeds,
        patientId: model.patientId,
        startDate: model.startDate,
      };
      store.commit('setTreatmentPlan', calOptions.treatmentPlan);
      store.commit('setRegimen', calOptions.regimen);
      store.commit('setBloodClot', calOptions.bloodClot);
      store.commit('setAntiViral', calOptions.antiViral);
      store.commit('setPreMeds', calOptions.preMeds);
      store.commit('setPatientId', calOptions.patientId);
      store.commit('setStartDate', calOptions.startDate);

      const calendar = CalendarGenerator.createCalendar(calOptions);
      store.commit('setCalendarDays', calendar.days);

      router.push({ name: 'new-builder' }, () => {});
    }

    function testPDF() {
      CreateCalendarPDF.CreateCalendar(testData.data);
    }

    const regimenOptions = computed(() => {
      let cycleOptions = [];
      if (model.treatment !== null) {
        model.treatment.cycles.forEach((cycle) => {
          cycleOptions = [...cycleOptions, ...rangeParser.parse(cycle.name)];
        });
      }
      return cycleOptions;
    });

    return {
      options,
      treatments,
      model,
      makeCycleLabel,
      makeOptionLabel,
      testPDF,
      regimenOptions,
      generateCalendar,
    };
  },
};
</script>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>
<style lang="scss" scoped>
  .container {
    max-width: $containerWidth - 400px;
    margin: 0 auto;
    padding: 1rem;
  }
  h1 {
    @include header;
    text-align: center;
    padding: 1rem 0;
  }
  .multiselect {
    margin-bottom: 1rem;
  }
  .picker {
    height: 40px;
    border-radius: 5px;
    border: 1px solid $inputBorder;
    padding: 0 1rem;
    font-size: 1rem;
    color: $textCalendar;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }
</style>
