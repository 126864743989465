export default {
  data: {
    calendarDays: [
      {
        dayNumber: 1,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 11,
            name: 'Daratumumab IV',
            medication_type: 4,
            created_at: '2020-02-15T19:29:20.606Z',
            updated_at: '2020-02-29T16:24:58.365Z',
            color: '#F8BBD0',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 9,
            name: 'Montelukast',
            medication_type: 3,
            created_at: '2020-02-15T19:28:30.533Z',
            updated_at: '2020-02-29T16:24:36.935Z',
            color: '#DCEDC8',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 2,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 11,
            name: 'Daratumumab IV',
            medication_type: 4,
            created_at: '2020-02-15T19:29:20.606Z',
            updated_at: '2020-02-29T16:24:58.365Z',
            color: '#F8BBD0',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 3,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 4,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 5,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 6,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 7,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 8,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 11,
            name: 'Daratumumab IV',
            medication_type: 4,
            created_at: '2020-02-15T19:29:20.606Z',
            updated_at: '2020-02-29T16:24:58.365Z',
            color: '#F8BBD0',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 9,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 10,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 11,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 12,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 13,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 14,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 15,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 11,
            name: 'Daratumumab IV',
            medication_type: 4,
            created_at: '2020-02-15T19:29:20.606Z',
            updated_at: '2020-02-29T16:24:58.365Z',
            color: '#F8BBD0',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 16,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 17,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 18,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 19,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 20,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 21,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 22,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 11,
            name: 'Daratumumab IV',
            medication_type: 4,
            created_at: '2020-02-15T19:29:20.606Z',
            updated_at: '2020-02-29T16:24:58.365Z',
            color: '#F8BBD0',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 23,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
          {
            id: 12,
            name: 'Dexamethasone',
            medication_type: 4,
            created_at: '2020-02-15T20:31:05.097Z',
            updated_at: '2020-02-29T16:26:21.290Z',
            color: '#B3E5FC',
            color2: '#D1C4E9',
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 24,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 25,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 26,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 27,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
      {
        dayNumber: 28,
        medications: [
          {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
            enabled: true,
          },
        ],
      },
    ],
    regimen: 1,
    bloodClot: {
      __component: 'med-plans.medication-plan',
      id: 5,
      medication: {
        id: 6,
        name: 'Asprin 81mg',
        medication_type: 1,
        created_at: '2020-02-15T19:24:57.697Z',
        updated_at: '2020-02-29T16:23:51.356Z',
        color: '#F0F3C3',
        color2: null,
        pillDescription: null,
        instructions: null,
        otherInfo: null,
        pillAppearance: null,
      },
      days: null,
      specialInstructions: null,
      dosageType: 'daily',
      length: 28,
      givenByNurse: null,
    },
    antiViral: {
      __component: 'med-plans.medication-plan',
      id: 3,
      medication: {
        id: 4,
        name: 'Valacyclovir',
        medication_type: 2,
        created_at: '2020-02-15T19:24:30.667Z',
        updated_at: '2020-02-29T16:23:27.930Z',
        color: '#BBDEFB',
        color2: null,
        pillDescription: null,
        instructions: null,
        otherInfo: null,
        pillAppearance: null,
      },
      days: null,
      specialInstructions: null,
      dosageType: 'daily',
      length: 21,
      givenByNurse: null,
    },
    preMeds: [
      {
        __component: 'med-plans.medication-plan',
        id: 1,
        medication: {
          id: 9,
          name: 'Montelukast',
          medication_type: 3,
          created_at: '2020-02-15T19:28:30.533Z',
          updated_at: '2020-02-29T16:24:36.935Z',
          color: '#DCEDC8',
          color2: null,
          pillDescription: null,
          instructions: null,
          otherInfo: null,
          pillAppearance: null,
        },
        days: '0,1',
        specialInstructions: null,
        dosageType: 'specificDays',
        length: 0,
        givenByNurse: null,
      },
    ],
    patientId: '1234',
    startDate: '2020-02-24',
    treatmentPlan: {
      id: 7,
      name: 'Dara/Dexa/Len',
      created_at: '2020-02-15T20:21:52.080Z',
      updated_at: '2020-02-15T20:28:39.951Z',
      shortName: 'DRd',
      treatmentLength: 28,
      preMedOptions: [
        {
          __component: 'med-plans.medication-plan',
          id: 1,
          medication: {
            id: 9,
            name: 'Montelukast',
            medication_type: 3,
            created_at: '2020-02-15T19:28:30.533Z',
            updated_at: '2020-02-29T16:24:36.935Z',
            color: '#DCEDC8',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
          },
          days: '0,1',
          specialInstructions: null,
          dosageType: 'specificDays',
          length: 0,
          givenByNurse: null,
        },
        {
          __component: 'med-plans.medication-plan',
          id: 2,
          medication: {
            id: 10,
            name: 'Rupatadine',
            medication_type: 3,
            created_at: '2020-02-15T19:28:46.203Z',
            updated_at: '2020-02-29T16:24:47.857Z',
            color: '#FFCCBC',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
          },
          days: '0,1',
          specialInstructions: null,
          dosageType: 'specificDays',
          length: null,
          givenByNurse: null,
        },
      ],
      antiviralTreatment: [
        {
          __component: 'med-plans.medication-plan',
          id: 3,
          medication: {
            id: 4,
            name: 'Valacyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:30.667Z',
            updated_at: '2020-02-29T16:23:27.930Z',
            color: '#BBDEFB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
          },
          days: null,
          specialInstructions: null,
          dosageType: 'daily',
          length: 21,
          givenByNurse: null,
        },
        {
          __component: 'med-plans.medication-plan',
          id: 4,
          medication: {
            id: 5,
            name: 'Acyclovir',
            medication_type: 2,
            created_at: '2020-02-15T19:24:44.605Z',
            updated_at: '2020-02-29T16:23:41.901Z',
            color: '#B2DFDB',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
          },
          days: null,
          specialInstructions: null,
          dosageType: 'daily',
          length: 21,
          givenByNurse: null,
        },
      ],
      bloodClotPrevention: [
        {
          __component: 'med-plans.medication-plan',
          id: 5,
          medication: {
            id: 6,
            name: 'Asprin 81mg',
            medication_type: 1,
            created_at: '2020-02-15T19:24:57.697Z',
            updated_at: '2020-02-29T16:23:51.356Z',
            color: '#F0F3C3',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
          },
          days: null,
          specialInstructions: null,
          dosageType: 'daily',
          length: 28,
          givenByNurse: null,
        },
      ],
      rulesForAllCycles: [
        {
          __component: 'med-plans.medication-plan',
          id: 6,
          medication: {
            id: 2,
            name: 'Revlimid',
            medication_type: 4,
            created_at: '2020-02-15T19:23:50.313Z',
            updated_at: '2020-02-29T16:23:16.878Z',
            color: '#E1BEE7',
            color2: null,
            pillDescription: null,
            instructions: null,
            otherInfo: null,
            pillAppearance: null,
          },
          days: '',
          specialInstructions: null,
          dosageType: 'daily',
          length: 21,
          givenByNurse: null,
        },
      ],
      cycles: [
        {
          __component: 'cycles.cycle',
          id: 1,
          name: '1',
          medicationPlans: [
            {
              id: 7,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1,2,8,15,22',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: true,
              daysArray: [1, 2, 8, 15, 22],
            },
            {
              id: 8,
              medication: {
                id: 11,
                name: 'Daratumumab IV',
                medication_type: 4,
                created_at: '2020-02-15T19:29:20.606Z',
                updated_at: '2020-02-29T16:24:58.365Z',
                color: '#F8BBD0',
                color2: null,
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1,2,8,15,22',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
              daysArray: [1, 2, 8, 15, 22],
            },
            {
              id: 9,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '9,16,23',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
              daysArray: [9, 16, 23],
            },
          ],
        },
        {
          __component: 'cycles.cycle',
          id: 2,
          name: '2',
          medicationPlans: [
            {
              id: 10,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1,8,15,22',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: true,
            },
            {
              id: 11,
              medication: {
                id: 11,
                name: 'Daratumumab IV',
                medication_type: 4,
                created_at: '2020-02-15T19:29:20.606Z',
                updated_at: '2020-02-29T16:24:58.365Z',
                color: '#F8BBD0',
                color2: null,
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1,8,15,22',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
            },
            {
              id: 12,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '2,9,16,23',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
            },
          ],
        },
        {
          __component: 'cycles.cycle',
          id: 3,
          name: '3,4,5,6',
          medicationPlans: [
            {
              id: 13,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1,15',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: true,
            },
            {
              id: 14,
              medication: {
                id: 11,
                name: 'Daratumumab IV',
                medication_type: 4,
                created_at: '2020-02-15T19:29:20.606Z',
                updated_at: '2020-02-29T16:24:58.365Z',
                color: '#F8BBD0',
                color2: null,
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1,15',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
            },
            {
              id: 15,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '2,8,15,22',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
            },
          ],
        },
        {
          __component: 'cycles.cycle',
          id: 4,
          name: '7',
          medicationPlans: [
            {
              id: 16,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '1',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: true,
            },
            {
              id: 18,
              medication: {
                id: 12,
                name: 'Dexamethasone',
                medication_type: 4,
                created_at: '2020-02-15T20:31:05.097Z',
                updated_at: '2020-02-29T16:26:21.290Z',
                color: '#B3E5FC',
                color2: '#D1C4E9',
                pillDescription: null,
                instructions: null,
                otherInfo: null,
                pillAppearance: null,
              },
              days: '2,8,15,22',
              specialInstructions: null,
              dosageType: 'specificDays',
              length: null,
              givenByNurse: null,
            },
          ],
        },
      ],
    },
  },
};
