import rangeParser from 'parse-numeric-range';

function getCyclesArray(plan) {
  const cyclesObject = {};
  plan.cycles.forEach((cycleGroup) => {
    const cycleNumbers = rangeParser.parse(cycleGroup.name);
    cycleNumbers.forEach((cycle) => {
      cyclesObject[cycle] = { ...cycleGroup };
    });
  });
  return cyclesObject;
}

function getPlanDaysArray(plan) {
  let daysArray = [];
  switch (plan.dosageType) {
    case 'daily':
      for (let i = 1; i <= plan.length; i += 1) {
        daysArray.push(i);
      }
      break;
    case 'everyXdays':
      for (let i = 1; i <= plan.length; i += plan.daysInterval) {
        daysArray.push(i);
      }
      break;
    default:
      daysArray = rangeParser.parse(plan.daysRange);
      break;
  }
  return daysArray;
}

export default {
  createCalendar(options) {
    /* options: {
      treatmentPlan: {},
      regimen: -1,
      bloodClot: {},
      antiViral: {},
      preMeds: [],
      patientId: '',
      startDate: '',
    }
    */

    const days = [];

    const cyclesObject = getCyclesArray(options.treatmentPlan);

    /* Regular cycles meds */
    const cyclePlans = cyclesObject[options.regimen].medicationPlans;

    cyclePlans.forEach((plan, index) => {
      cyclePlans[index].daysArray = getPlanDaysArray(plan);
    });

    /* Blood clot plan */
    let bloodClotPlan = null;
    if (options.bloodClot !== null) {
      bloodClotPlan = { ...options.bloodClot };
      bloodClotPlan.daysArray = getPlanDaysArray(bloodClotPlan);
    }

    /* anti-viral plan */
    let antiViralPlan = null;
    if (options.antiViral !== null) {
      antiViralPlan = { ...options.antiViral };
      antiViralPlan.daysArray = getPlanDaysArray(antiViralPlan);
    }

    /* all cycle plans */
    const allCyclePlans = [];
    options.treatmentPlan.rulesForAllCycles.forEach((med) => {
      allCyclePlans.push({
        ...med,
        daysArray: getPlanDaysArray(med),
      });
    });

    /* Pre-Med Plans */
    const preMedPlans = [];
    options.preMeds.forEach((premed) => {
      preMedPlans.push({
        ...premed,
        daysArray: getPlanDaysArray(premed),
      });
    });

    for (let i = 1; i <= options.treatmentPlan.treatmentLength; i += 1) {
      const day = {
        dayNumber: i,
        medications: [],
      };

      if (bloodClotPlan !== null && bloodClotPlan.daysArray.includes(i)) {
        day.medications.push({
          ...bloodClotPlan.medication,
          enabled: true,
          givenByNurse: bloodClotPlan.givenByNurse,
        });
      }
      if (antiViralPlan !== null && antiViralPlan.daysArray.includes(i)) {
        day.medications.push({
          ...antiViralPlan.medication,
          enabled: true,
          givenByNurse: antiViralPlan.givenByNurse,
        });
      }

      cyclePlans.forEach((plan) => {
        if (plan.daysArray.includes(i)) {
          day.medications.push({
            ...plan.medication,
            enabled: true,
            givenByNurse: plan.givenByNurse,
          });
        }
      });

      allCyclePlans.forEach((plan) => {
        if (plan.daysArray.includes(i)) {
          day.medications.push({
            ...plan.medication,
            enabled: true,
            givenByNurse: plan.givenByNurse,
          });
        }
      });

      preMedPlans.forEach((plan) => {
        if (plan.daysArray.includes(i)) {
          day.medications.push({
            ...plan.medication,
            enabled: true,
            givenByNurse: plan.givenByNurse,
          });
        }
      });

      days.push(day);
    }

    return {
      days,
      ...options,
    };
  },
};
